@font-face {
  font-family: "Showcard Gothic";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/SHOWG.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html,
body {
  height: 100%;
  background-color: #f2f3f5;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}
